@media (min-width: 600px) {

    .containerImmaginiDiv{
        display:grid;
        grid-template-columns: 25% 25% 25%;
        gap:5%;
        text-align:center;
    }
    .containerImmaginiDiv span{
        font-weight: 500;
    }

    .containerImmaginiDiv img{
        max-height: 25em;
        max-width: -webkit-fill-available;
        cursor:pointer;
    }

    .divInserimentoImmagini{
        margin:1em 0;
    }

    .spanElimina{
        background: white;
        position: absolute;
        top: 0;
        right: 20%;
        cursor: pointer;
        padding:0.2em;
        transition: all 0.5s;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4)); 
    }
    .spanElimina:hover{
        background: #DB2219;
        color:white;
        transition: all 0.2s;
        padding:0.5em;
    }

    .singolaImmagine{
        position:relative;
        margin:0.5em;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4)); 
    }

    .immagineApertaDiv{
        opacity: 1;
        z-index: 5;
        transition: all 0.5s;
        position: fixed;
        top: 0em;
        right: 1em;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);
    }
    .immagineApertaDiv img{
        cursor: pointer;
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
    
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }

    .contenitoreBottoniTipoImmagine{
        display: inline-block;
    } 

    .bottoniTipoImmagine{
        margin:1em;
        padding:0.5em 1em;
        background: white;
        border-radius:8px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    .selectedType{
        background: #486152;
        color:white;
    }
    .bottoneZip{
        margin:1em;
        padding:0.5em 1em;
        background: #486152;
        color:white;
        border-radius:8px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        position: absolute;
        right: 5%;
    }

}


@media (max-width: 600px) {
    .containerImmaginiDiv{
        font-size:x-large;
        display: grid;
        grid-template-rows: auto auto auto;
    }   
    .containerImmaginiDiv span{
        font-weight: 500;
    }

    .singolaImmagine{
        position:relative;
        margin:0.5em;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4)); 
    }

    .singolaImmagine img{
        max-height: 25em;
        max-width: 20em;
        cursor:pointer;
    }

    .containerImmaginiRichiesta, .containerImmaginiPrima, .containerImmaginiDopo {
        display: flex;
        overflow-x: scroll;
        width: 15em;
        margin-bottom:2em ;
    }         
    .spanElimina{
        background: white;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding:0.2em;
        transition: all 0.5s;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4)); 
        font-size:x-large;
    }
    .contenitoreBottoniTipoImmagine{
        display:flex;
        align-items: center;
    }
    .bottoniTipoImmagine{
        font-size: small;
        margin:1.3em 0.5em;
        padding:0.7em 1em;
        background: #dce2e1;
        border-radius:8px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }
    .selectedType{
        background: #486152;
        color:white;
        font-size: large;
    }       
    .immagineApertaDiv{
        opacity: 1;
        z-index: 5;
        transition: all 0.5s;
        position: fixed;
        top: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);

    }
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }
    .immagineApertaDiv img{
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
    .divInserimentoImmagini{
        background: white;
        padding: 1em;
        margin: 0.5em;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
    }
    .labelAggiungi{
        margin:0.5em auto;
        font-size: x-large;
    }
    .divInserimentoImmagini input{
        margin-bottom:1.5em;
    }

    .labelSelezionaTipo{
        margin: 0 auto;
        font-size: large;
    }
    .bottoneZip{
        margin:1em 2em;
        padding:0.5em 1em;
        background: #486152;
        color:white;
        border-radius:8px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }
}