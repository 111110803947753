

@media (min-width: 600px) {
    .modaleIniziale{
        position: absolute;
        width: 100%;
        height: 1000%;
    }

    .modalContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1%;
    }


    .titoliModal{
        display: flex;
        justify-content: space-around;
        font-weight: 700;
        width: 30%;
        padding: 2%;
        margin-top:3em;
    }

    .titoloModalSx{
        cursor:pointer;
        padding:1%;
    }

    .titoloModalDx{
        cursor:pointer;
        padding:1%;
    }

    .titoloModalSx:hover{
        color:#D41B12;
    }
    .titoloModalDx:hover{
        color:#D41B12;
    }

    .titoliModal .active{
        color:#D41B12;
        border-bottom:1px solid;
    }
    .contenutoModal{
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .bottoneEntra{
        color:white;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        padding:12px;
        background:#D41B12;
        border-bottom:3px solid #97130c;
        border-right:2px solid #97130c;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        margin:1em 1em;
    }

    .bottoneEntra:active{
        border:none;
    }

    .contenutoModal input{
        margin:4%;
        border: 1px solid;
    }
    .payoffModal{
        display:flex;
        align-items: center;
    }

    .payoffModal img{
        background:#486152;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        height: 180px;
        margin: 0 1em;
        padding-top:15px;
    }
    .payoffModal p{
        font-size:xx-large;
        font-weight:bold;
        color:#486152;
    }


}

@media (max-width: 600px) {

    .modaleIniziale{
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        z-index:5;
    } 

    .titoliModal{
        display: flex;
        justify-content: space-around;
        font-weight: 700;
        font-size:x-large;
    }
    /*     .titoliModal{
        display: flex;
        justify-content: space-around;
        font-weight: 700;
        width: 30%;
        padding: 2%;
        margin-top:3em;
    } */

    .titoloModalSx{
        cursor:pointer;
        padding:1%;
    }

    .titoloModalDx{
        cursor:pointer;
        padding:1%;
    }

    .titoloModalSx:hover{
        color:#D41B12;
    }
    .titoloModalDx:hover{
        color:#D41B12;
    }

    .titoliModal .active{
        color:#D41B12;
        border-bottom:1px solid;
    }

    .bottoneEntra{
        color:white;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        font-size: x-large;
        padding:3%;
        background:#D41B12;
        border-bottom:3px solid #97130c;
        border-right:2px solid #97130c;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        margin:1em 1em;
    }

    .bottoneEntra:active{
        border:none;
    }

    .contenutoModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:4%;
        margin-top:2%;
    }
/*     .modalContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1%;
    } */
    .contenutoModal input{
        font-size: larger;
        margin:7%;
        border: 1px solid;
    }
    .payoffModal{
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .payoffModal img{
        background:#486152;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        height: 120px;
        margin: 1em;
        padding-top:15px;

    }
    .payoffModal p{
        font-size:xx-large;
        font-weight:bold;
        color:#486152;
        text-align: center;
        padding:0 1em 1em;
    }
}

@media only screen and (orientation: landscape) {

}