@media (min-width: 600px) {

    .tabellaHomeMade{
        margin:2em 1.7em 1em;
        background:white;
        padding:2em;
        border-radius:0.7em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        grid-column:1/3;
        min-height: 12em;
    }
    .rowTabella{
        display:grid;
        grid-template-columns: 2em 8em auto 10em 11em  ;
        margin:0.5em 0;
        transition: all 0.5s;

    }
    .rowTabella:hover{
        cursor:pointer; 
        text-decoration: underline;
        text-underline-offset: 2px;
        transition: all 0.2s;
        padding:0.2em 0;
    }
    .tabellaHomeMadeTitle{
        font-weight: bold;
        font-size: larger;
        text-align: center;
        margin-bottom:2em ;
    }

}

@media (max-width: 600px) {
    .tabellaHomeMade{
        margin:2em 0;
    }
    .tabellaHomeMadeTitle{
        margin:2em 0;
        font-weight: 500;
        font-size: x-large;
        text-align: center;
    }
    .rowTabella{
        margin:1em 0;
        padding:1em 1em;
        background:white;
        border-radius: 5px;
        
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px; 
        display: grid; 
        align-items: center;
        font-size: large;
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows:auto auto auto auto; 
        gap: 15px 5px; 
        grid-template-areas: 
            "id municipio municipio municipio"
            "indirizzo indirizzo indirizzo indirizzo"
            "priorità priorità priorità priorità"
            "tipoRichiesta tipoRichiesta tipoRichiesta tipoRichiesta";
    }


    .rowTabellaId { 
        grid-area: id; 
        text-align:center;
        background:#dce2e1;
        border-radius:2em;
        padding:0.5em 0;

    }
    .rowTabellaMunicipio { 
        grid-area: municipio;
        text-align:center;
        border-radius:2em;
        padding:0.5em 0;
        background:#dce2e1;

    }
    .rowTabellaPriority { grid-area: priorità; }
    .rowTabellaTipoR { grid-area: tipoRichiesta; }
    .rowTabellaIndirizzo { grid-area: indirizzo; }


}