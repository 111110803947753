

.cls-1{
    /* fill:#c1c1c1; */
    stroke:#486152;
    transition: all 0.4s;
}

.cls-1:hover{
    /* fill:#DB2219; */
    cursor:pointer;
}
.attivo{
    fill:#DB2219;
    cursor:pointer;
}
.testiPercentuali{
    font-size: xx-large;
}

.counterMunicipi{
    color:white;
    padding: 0 2em 1em 2em;
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.counterMunicipi h4{
    text-transform: uppercase;
    font-size: x-large;
    text-align: end;

    margin:0.5em 0;
}
.counterMunicipi p{
    text-align: end;
    margin-top:1em;
}
.counterMunicipi span{
    text-align: end;
    font-size: x-small;
}
.numeriDashboard{
    display:inline;
    text-align: end;
    font-size: x-large;
    padding-top:0.5em;
}
.legendaPercentuali{
    color:white;
    padding: 2em;
    padding-bottom:0em;
    /* width: 90%; */
    text-align: end;
    position:relative;
}
.legendaPercentuali h4{
    cursor:pointer;
}
.legendaPercentuali h4:hover{
    color:#DB2219
}

.tooltipLegenda p{
    font-size: small;
    font-weight: bold;
}
.tooltipLegenda h5{
    font-size: small;
    padding:0.5em 0;
    font-weight:500;
    text-align: justify;

}
.tooltipLegenda{
    visibility: hidden;
    opacity:0;
    position:absolute;
    bottom:3.5rem;
    right:2em;
    z-index:3;
    background-color:#486152;
    padding:0.5em 0;
    transition: all 0.3s;
}
.tooltipAperto{
    visibility: visible;
    opacity:1;
    transition: all 0.3s;
}

.svgContainer g{
    cursor:pointer;
} 


@media (min-width: 800px) {
    .svgContainer{
        width: 150%;
        padding: 2em;
    }

    
    .welcomeDivMapLeft{
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
@media (max-width: 800px) {
    .svgContainer{
        width: 90%;
        margin:0 auto;
        padding-top:2em;;
    }
    .welcomeDivMapLeft{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .legendaPercentuali{
        padding-bottom:2em;
    }

    .tooltipLegenda{
        bottom: 6em;
        right: 0;
        padding: 1.5em 2em;
        width: 80%;
    }
}

