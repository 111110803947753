@media (min-width: 800px) {
    
    .strisciaMeteo{
        display:grid;
        grid-template-columns: 25% 25% 25% 25%;
    }
    .headerDashboardContainer{
        height:8em;
        background: #486152;
        margin-bottom:1em;
        border-bottom-left-radius: 25px;
        border-top-right-radius: 25px; 
        display:grid;
        grid-template-columns: 38% 62%;
        color:white;
    }
}



.allertaContainer{
    text-align:center;
    
}
.allertaContainer a{
    text-decoration:none;
    color:white;

}
.allertaContainer p{
    padding-top:1em;
}

.allertaTitle{
    cursor: pointer;
    color: #486152;

}
.allertaTitle:hover{
    font-weight: bold;
}


.allertaButton{
    cursor: pointer;
    background-color: white;
    color: #486152;
    margin: 2% 2% 0.5%;
    padding: 2% 2%;
    border-radius: 0.2em;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: all 0.4s;
    display: inline-block;
}

.allertaButton:hover {
    box-shadow: 5px 5px 10px 0px #032410;
}

.dataUltimoLink{
    font-size: xx-small;
    /* margin-top:0.5em; */
}


.oreMeteoContainers img{
    height: 50px;
}

.oreMeteoContainersCurrent img{
    height: 70px;
}

.oreMeteoContainers{
    display: grid;
    grid-template-columns: 48% 52%;
    flex-direction: column;
    align-items: center;
    margin: 0 1.5em;
}
.oreMeteoContainersCurrent{
    display: grid;
    grid-template-columns: 50% 50%;
    flex-direction: column;
    align-items: center;/* 
    margin: 0.7em 1.5em;
    background-color: white;
    color: #486152;
    padding:1%;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    padding: 0 1em;
    font-weight: bold;
    border: 1px solid #757575;
    font-weight: bold;

}
.oreMeteoContainersCurrent p{
    font-size: x-large;
}

.leftMeteoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.rightMeteoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.rightMeteoContainer span{
    font-size: small;
    margin:0.3em 0;
}


@media (max-width: 800px) {
    .strisciaMeteo{
        display:none;
    }
    .headerDashboardContainer{
        display:flex;
        justify-content: center;

        height:8em;
        background: #486152;
        margin-bottom:1em;
        border-bottom-left-radius: 25px;
        border-top-right-radius: 25px; 
        color:white;
    }
}