
@media (min-width: 600px) {

    .headGestioneMezzi{
        text-align: center;
        font-size: larger;
    }
    .inserimentoMezziButton{
        cursor: pointer;    
        background-color:#486152;
        color:white;
        margin:3% 2%;
        padding:3% 2%;
        border-radius:0.6em;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        transition:all 0.5s;
    }
    
    .listaMezziButton{
        cursor: pointer;
        background-color:#486152;
        color:white;
        margin:3% 2%;
        padding:3% 2%;
        border-radius:0.6em;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        transition:all 0.5s;
    }
    
    
    .listaMezziButton:hover{
        -webkit-box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0); 
        box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0);
    }
    
    .inserimentoMezziButton:hover{
        -webkit-box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0); 
        box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0);
    }
    
    .bottoniContainer{
        display: flex;
        width: 50%;
        padding: 1%;
        justify-content: space-around;
    }
    
    .inserimentoMezzoContainer{
        margin:1%;
        padding:2%;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
    }
    
    .listaMezzoContainer{
        margin:1%;
        padding:3em;
        background:white;
        border-radius:3em;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    
    .campoInserimentoMezzo{
        margin:1%;
        display:grid;
        grid-template-columns: 30% 30%;
    }
    
    .singoloMezzoContainer{
        display:grid;
        grid-template-columns: 20% 20%  15% 30% 10% 10%;
        margin: 0.5% 0;
        padding: 0.5em 1em;
    }
    
/*     .singoloMezzoContainer:hover{
        border-bottom:1px solid;
    } */
    
    .headSingolaMezzoContainer{
        display:grid;
        grid-template-columns:  20% 20% 15% 28%;
        margin: 1% 0;
        font-weight: bold;
        padding: 0.5em;
    }
    
    .mezziButtonsNotDeleted{
        cursor:pointer;
        color: #006F61;
        text-align:center;
    }
    
    .mezziButtonsNotDeleted:hover {
        font-weight: bold;
    }
    
    .mezziButtonsDeleted{
        cursor:pointer;
        color: red;
        text-align:center;
    }
    
    .mezziButtonsDeleted:hover {
        font-weight: bold;
    }
    .iconaTruckMezzi{
        display:none;
    }
    .bottoneModificaMezzo{
        cursor: pointer;
    }
    .bottoneModificaMezzo:hover{
        font-weight: bold;
    }

}

.red{color:red}
.green{color:green}

@media (max-width: 600px) {
    .headGestioneMezzi{
        font-size: larger;
        text-align: center;
        padding:1em;
    }

    .bottoniContainer{
        display: flex;
        padding: 1em;
        justify-content: space-around;
    }

    .listaMezziButton{
        font-size: larger;
        cursor: pointer;
        background-color:#486152;
        color:white;
        margin:0.5em;
        padding:1em 0.5em;
        border-radius:0.6em;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        transition:all 0.5s;
    }

    .inserimentoMezziButton{
        font-size: larger;
        cursor: pointer;    
        background-color:#486152;
        color:white;
        margin:0.5em;
        padding:1em 0.5em;
        border-radius:0.6em;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        transition:all 0.5s;
    }
        
    .listaMezzoContainer{
        margin:0.8em;
    }
    .inserimentoMezzoContainer{
        margin:0.8em;
        padding:1em;
        font-size: large;
    }
    .headSingolaMezzoContainer{
        display: none; 
        } 
    
    .singoloMezzoContainer{
        display: grid; 
        align-items: center;
        background-color: white;
        margin:1em 0;
        padding:1em ;
        border-radius: 5px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr; 
        gap: 10px 0px; 
        grid-template-areas: 
            "targa targa eliminaMezzo eliminaMezzo"
            "capoSquadra capoSquadra capoSquadra loggato"
            "tipomezzo tipomezzo tipomezzo tipomezzo"; 
        }

    .targa { grid-area: targa; font-weight: 600; }
    .eliminaMezzo { grid-area: eliminaMezzo; }
    .capoSquadra { grid-area: capoSquadra; }
    .loggato { grid-area: loggato; text-align: center;}
    .tipomezzo { 
        grid-area: tipomezzo;  
        display:block;
    }
    .mezziButtonsNotDeleted{
        background-color: #e7eceb;
        padding:0.5em 0;
        cursor:pointer;
        color: #006F61;
        text-align:center;
        font-weight: bold;
        border-radius:5px;
    }

    .mezziButtonsDeleted{
        cursor:pointer;
        color: red;
        font-weight: bold;
        border-radius:5px;
        text-align:center;  
        background-color: #e7eceb;
        padding:0.5em 0;
    }
    .campoInserimentoMezzo{
        margin:1em 0;
        display:grid;
        grid-template-columns: 50% 50%;
    }
    .bottoneModificaMezzo{
        background-color: #e7eceb;
        padding:0.5em 0;
        cursor:pointer;
        color: #006F61;
        text-align:center;
        font-weight: bold;
        border-radius:5px;
    }
}