/* .footerContainer{
    position: absolute;
    bottom: 1em;
    width: 100%;
} */

.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:1em;
}
.footer span{
    font-size:small;
    margin: 1em;
}

.logoAVR{
    height:30px;

}