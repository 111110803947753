
@media (min-width: 600px) {
    .titoloRicercaPage{
      font-size: large;
      text-align: center;
    }

    .filterRichiesteContainer{
        margin: 2% 0 ;
    }

    /* IMPOSTACOLONNE COMPONENT */
    .selezionaColonneDiv{
        display: grid;
        grid-template-columns: auto auto auto auto auto;
    }

    .selezionaColonneCheckbox{
        display: flex;
        margin: 0.8%;
    }
  .containerTabella {
      display: block;
      overflow-x: scroll;
      overflow-y: auto;
      margin-top: 3em;
  }
    table{
        margin: 0.5em;
        padding-bottom:1em;
    }

    th, td {
      padding: 10px 5px;
    }

    .rowTableRichiesta:nth-child(even) {
      background: #eef5f4;
    }

    .rowTableRichiesta{
      cursor:pointer;

    }

    .rowTableRichiesta:hover{
      font-weight: 500;
    }


    /* CONTAINER RICERCA PER DATE */
    .containerRicercaComponents{
      display:inline-grid;
      grid-template-columns: 50% 50%;
      width: 70%;
      padding: 2em 0 0;
    }

    .ricercaInput{
      margin:1%;
    }

    .deleteDateButton{
      cursor:pointer;
      display:inline-block;
      padding:0.5em;
      font-size: x-large;
    }

    .ricercaButtons{
      cursor: pointer;
      background-color: #486152;
      color: white;
      padding: 1em 1em;
      border-radius:0.5em;
      border-bottom-right-radius: 25px;
      border-top-left-radius: 25px;
      transition:all 0.3s;
      width: 10em;
      text-align: center;
    }
    .apriMappaComponent .ricercaButtons{
      display:inline;
    }


    .contenutoMain .attivo{
      background-color: #DB2219; 
    }

    .ricercaButtons:hover{
      -webkit-box-shadow: 8px 7px 16px 0px #486152;
      box-shadow: 8px 7px 16px 0px #486152;
    }

    /* CONTAINER RICERCA GLOBALE, IMPOSTA COLONNE */
    .containerRicercaEColonne{
      display:inline-grid;
      grid-template-columns: 50% 50%;
      width: 70%;
      padding: 2em 0 ;
    }

    .apriMappaComponent{
      display: inline;
    }
    .iconaMappaRicerca{
      font-size:x-large;
      margin:0 0.6em 0 1em;
    }

    .containerRicercaEColonne .ricercaInput{
      margin-top: 1em;
      display: inline;
    }
    .ricercaInputContainer{
      margin:1em 0;
      display: flex;
      align-items: center;
    }
    .selezionaColonneCheckbox input{
      width: 15px;
      height: 15px;
      filter: invert(100%) hue-rotate(48deg) brightness(0.8);
    }
    .Flipped, .Flipped table{
      transform: rotateX(180deg);
  }
}




.apriMappaComponent a{
  text-decoration: none;
  color:white;
}
.avviso{
  text-align: center;
}
.red{color:red}
.green{color:green}

@media (max-width: 850px) and (min-width: 600px){
  .apriMappaComponent {
    display: block;
    margin: 3em 0;
  }
  .containerRicercaEColonne {
    width: 100%;
  }
  .containerRicercaComponents {
    width: 100%;
  }
}


@media (max-width: 600px) {
  .titoloRicercaPage{
    font-size: larger;
    text-align: center;
    padding: 1em;
  }
  .ricercaButtons{
    cursor: pointer;
    background-color: #486152;
    color: white;
    padding: 1em 1em;
    border-radius:0.5em;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
    transition:all 0.5s;
    width: 10em;
    margin:2em auto 1em;
    text-align: center;
    font-size: larger

  }
  .contenutoMain .attivo{
    background-color: #DB2219; 
  }

  .deleteDateButton{
    cursor:pointer;
    display:inline-block;
    font-size: xx-large;
  }
  .ricercaInputContainer{
    margin:1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ricercaInput input{
    font-size: large;
  }

  .ricercaGlobaleComponent{
    text-align: center;
  }
  table{
    padding:0.5em;
    overflow: auto;
    display: block;
    font-size: larger;
  }

  th, td {
    padding: 5px 5px;
  }

  .iconaMappaRicerca{
    margin-left: 1em;
  }

  .rowTableRichiesta{
    cursor:pointer;
    padding:1%;
  }
  .rowTableRichiesta:nth-child(even) {
    background: #eef5f4;
  }
  .selezionaColonneDiv{
    font-size: large;
    padding: 1em;
  }
  .selezionaColonneCheckbox input{
    width: 20px;
    height: 20px;
    filter: invert(100%) hue-rotate(48deg) brightness(0.8);
  }
  .containerRicercaEColonne .ricercaInput{
    display: inline;
  }
}