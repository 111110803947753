.bodyStoricoEmergenze{
    padding:0 2%;
}

.selectStorico{
    font-weight: 600;
}

.storicoEmergenze hr{
    border-top: 0.5px solid #486152;
    margin: 2em 0;
}
.goToArchivioRichieste{
    font-weight: bold;
    color: #486152;
    display:grid;
    grid-template-columns: 3em auto;
    align-items: center;
    cursor: pointer;
    margin: 1.5em 0;
}

.goToStoricoEmergenze{
    font-weight: bold;
    color: #486152;
    display:grid;
    grid-template-columns: 2em auto;
    align-items: center;
    cursor: pointer;
    margin-bottom:2em;
}


.bottoneConcludiEmergenza{
    font-size: large;
    margin: 1.3em 0.5em;
    padding: 0.7em 1em;
    background: #DB2219;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;

    border-bottom: 3px solid #97130c;
    border-right: 2px solid #97130c;
    transition: all 0.5s;
}

.bottoneConcludiEmergenza:active {
    border: none;
}

.containerButton{
    text-align: center;
}
.titoloStoricoEmergenze{
    font-weight: bold;
    font-size:x-large;
    color: #486152;
    margin:1em 0 2em;
}

.containerStatisticheEvento, .containerStatisticheAnnuali, .containerStatisticheMeteo{
    display:grid;
    grid-template-columns: 30% 30% 25em;
    grid-template-rows: auto auto auto;
    align-items: center;
    padding-left:3em;

}

.statisticheTipiRichieste{
    display: grid;
    grid-template-columns: 30% 60%;
    column-gap: 7%;
    align-items: center;
}
.containerStatisticheEvento span, .containerStatisticheAnnuali span{
    font-weight: bold;
    /* font-size:large; */
    color: #486152;
}

.containerStatisticheMeteo input{
    width:70%;
}
.iconaMeteo{
    height: 8em;
}

.containerCharts{
    background-color: white;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
    text-align: center;
    padding-top:1em;
}

.chart2{
    height:15em;
}
.strisciaStatsEvento{
    position:relative;
}
.testoNumeriMunicipi{
    font-size: xxx-large;
}

.cls-1:hover {
    cursor: unset;
}

.giornataCritica{
    grid-column:1/4;
}

.zonaCritica{
    grid-column:1/3;
    display: grid;
}

.zonaCriticaText{
    display:inline;
    margin:0 1em;
}

.containerChartAnnuale{
    margin: 3em 0;
}
.meteoBody{
    margin:1em 0;
}

.warningConcludi{
    padding:1em;
    font-size:small;
}


@media (max-width: 850px) and (min-width: 600px){
    .containerStatisticheEvento, .containerStatisticheAnnuali, .containerStatisticheMeteo{
        display:block;
    }
    .statisticheTipiRichieste {
        margin:2em 0;
    }
    .giornataCriticaDiv{
        margin:2em 0;
    }
    .zonaCritica{
        margin:2em 0;
    }
    .containerCharts{
        margin: 0 5em;
    }
}

@media (max-width: 600px) {
    .containerStatisticheEvento, .containerStatisticheAnnuali, .containerStatisticheMeteo{
        display:block;
        padding:0;
    }
    .statisticheTipiRichieste {
        margin:2em 0;
    }
    .giornataCriticaDiv{
        margin:2em 0;
    }
    .zonaCritica{
        margin:2em 0;
    }
    .imgContainer{
        text-align: center;
    }
}


