@media (min-width: 800px) {

    .containerBottoni{
        display:flex;
        justify-content: space-evenly;
    }

    .leftBottoni{
        cursor: pointer;
        background-color: #486152;
        color: white;
        margin-top: 2em;
        border-radius:10px;

        padding:  1em;

        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        transition:all 0.5s;
        width: 10em;
        text-align: center;
    }

    .rightBottoni{
        cursor: pointer;
        background-color: #486152;
        color: white;
        margin-top: 2em;
        border-radius:10px;
        padding:  1em;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        transition:all 0.5s;
        text-align: center;
        width: 10em;

    }

    .contenitoreMezzi{
        background-color: white;
        padding: 1em;
        border-radius: 15px;
        margin: 0 7.5em;
    }

    .contenitoreRichieste{
        padding:2em;
    }

    .containerBottoni .attivo{
        background-color: #DB2219;
    }

    .leftBottoni:hover{
        -webkit-box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0); 
        box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0);
    }

    .rightBottoni:hover{
        -webkit-box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0); 
        box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0);
    }

    .avvisoNoRichieste{
        padding: 2% 0 0 2%;
        font-weight: bold;
    }

    .titoloContainerMieRichieste{
        display: grid;
        grid-template-columns: 3em 25em 10em 20em 5em ;
        margin:1em 0;
        font-weight: 500;;
    }
    .singoleRichieste{
        display: grid;
        grid-template-columns: 3em 25em 10em 20em 5em ;
        padding:0.5em;
    }
    .singoleRichieste:hover{
        font-weight: bold;
        cursor:pointer;
    }
    .titoloMieRichieste{
        font-size:large;
        text-align: center;
    }
    .smallFont{
        font-size:x-small;
    }

}

.Segnalazione{
    color:green;    
    font-weight: 500;
}
.Emergenza{
    color:red;
    font-weight: 500;
}
.Urgenza{
    color:orange ;
    font-weight: 500;
}

@media (max-width: 800px) {

    .titoloMieRichieste{
        font-size: larger;
        text-align: center;
        padding: 1em;
    }

    .containerBottoni {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:1em 0;
    }

    .leftBottoni{
        cursor: pointer;
        background-color: #486152;
        color: white;
        padding: 1em 1em;
        border-radius: 0.5em;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        transition: all 0.5s;
        width: 10em;
        margin: 0.5em;
        text-align: center;
        font-size: larger;
    }

    .rightBottoni{
        cursor: pointer;
        background-color: #486152;
        color: white;
        padding: 1em 1em;
        border-radius: 0.5em; 
        
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        transition: all 0.5s;
        width: 10em;
        margin: 0.5em;
        text-align: center;
        font-size: larger;
    }

    .contenitoreMezzi{
        font-size: larger;
        padding: 2em 1em;
        margin: 1em;
        overflow: auto;
        background-color:white;
        border-radius: 15px;
        border-radius: 5px;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .contenitoreRichieste{
        padding: 1em;
    }

    .containerBottoni .attivo{
        background-color: #DB2219;
    }

    .leftBottoni:hover{
        -webkit-box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0); 
        box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0);
    }

    .rightBottoni:hover{
        -webkit-box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0); 
        box-shadow: 5px 5px 5px 0px #000000, -50px -50px 0px -7px rgba(0,0,0,0);
    }

    .avvisoNoRichieste{
        padding: 2% 0 0 2%;
        font-weight: bold;
    }
    .titoloContainerMieRichieste{
        display:none;
    }
    .smallFont{
        font-size:small;
    }

    .singoleRichieste {
        background-color: white;
        margin: 1em 0;
        padding: 1em;
        border-radius: 5px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        font-size: large;
        display: grid; 
        grid-template-columns: 0.8fr 2.2fr 1fr 1fr 0.5fr; 
        grid-template-rows: auto auto auto; 
        gap: 10px 0px; 
        grid-template-areas: 
            "id priority priority priority priority"
            "indirizzoCivico indirizzoCivico indirizzoCivico indirizzoCivico indirizzoCivico"
            " tipoIntervento tipoIntervento tipoIntervento tipoIntervento tipoIntervento"; 
    }
    .id { 
        grid-area: id; 
    }
    .priority { grid-area: priority; }
    .tipoIntervento { grid-area: tipoIntervento; }
    .indirizzoCivico { grid-area: indirizzoCivico; }
    .immaginiNumero { display:none; }
}


