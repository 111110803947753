

@media (min-width: 600px) {
    /* PARTE A SINISTRA */
    .sidebar{
        background: #486152;
        position:absolute;
        left:0;
        width: 14%;
        height: 100%;
        transition: all 0.5s;
        color:white;
    }

    .titleAGE{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: xx-large;
        font-weight: 600;
        margin:0.5em 0 0.3em;
    }
    .titleAGE img{
        height: 3em;
        cursor: pointer;

    }
    .titleAGE span{
        font-size:small;
    }

    .titleAGEspanChiuso{
        display:none;
    }

    .menuIcon{
        font-size: x-large;
        text-align: center;
        margin:0 auto !important;
        cursor:pointer;
    }
    .menuIcon:hover{
        color: #DB2219;
    }

    .sidebar p{
        margin:1em 0;
    }

    .sidebar a{
        text-decoration:none;
        color:white;
    }

    .sidebar a:hover{
        color: #DB2219;
        font-weight: bold;
    }

    .sidebar .active{
        font-weight: bold;
        color:#DB2219;
    }

    .contenutoSidebar{
        display:flex;
    }

    .iconeSidebar{
        display:grid;
        grid-template-rows: 3em 3em 3em 3em 3em 3em 3em;
        text-align: center;
        margin: 0 auto;
        font-size: x-large;
        text-align: center;
        padding: 0.4em 0;
    }

    .linkSidebar{
        display:grid;
        align-items: center;
        grid-template-rows: 4em 4em 4em 4em 4em 4em 4em;
        padding: 0.5em 0;
        font-size: large;
        transition: all 0.5s;
        margin: 0 1em 0 0;
    }

    .logOutDiv{
        width: 100%;
        text-align: center;
        position: absolute;
        bottom:1em;
        font-size: x-large;
    }

    .logOutIcon{
        cursor:pointer;
    }

    .logOutText{
        margin-top:0 auto !important;
        font-size: medium;
        font-weight: bold;
        color:red;
    }

    .dateTimeContainer{
        font-size: medium;
        width: 100%;
        text-align: center;
    }


    /* SIDEBAR CHIUSA */
    .sidebarChiusa{
        width:7%;
        transition: all 0.5s;
    }

    .mainChiuso{
        left:7% !important; 
        width:93% !important;
        transition: all 0.5s;
    }

    .linkSidebarChiuso{
        display:none;
        width:0%;
        transition: all 0.5s;
    }

    /* PARTE A DESTRA */
    .main{
        position: absolute;
        background:#dce2e1;
        left:14%; 
        width:86%;
        height:100%;
        overflow: auto;
        transition: all 0.5s;
    }

    .contenutoMain{
        padding:2%;
        min-height: 86%;
    }
    
    .immagineApertaDivSidebar{
        opacity: 1;
        z-index: 5;
        transition: all 0.2s;
        position: fixed;
        top: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(11px);
        flex-direction: column;
        font-size:x-large;
        font-weight: bold;
        color:#486152;
    }

    .immagineApertaDivSidebar img{
        cursor: pointer;
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
    
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }
    .payOffBig{
        background-color: white;
        padding:0.5em;
    }

}
    /* .immagineApertaDivSidebar img{
        animation: mymove 5s infinite;
    } */

@keyframes mymove {
    0% {transform: rotateY(0);}
    50% {transform: rotateY(180deg)}
    100% {transform: rotateY(360deg);}
}

@media (min-width: 600px) and (max-width: 850px) {
    .linkSidebar{
        display:none;
    }
    .sidebarChiusa {
        width: 10%;
    }
    .mainChiuso {
        left: 10% !important;
        width: 90% !important;
    }
    .titleAGE span{
        display:none;
    }

}


@media (max-width: 600px) {
/* PARTE A SINISTRA */
    .sidebar{
        z-index:1;
        background: #486152;
        position:absolute;
        top:0;
        height: 100%;
        width: 100%;
        transition: all 0.8s;
        color:white;
        padding-top:1em;
    }
    .menuIcon{
        font-size: x-large;
        /*  display: inline;
        margin: 1em ; */
        cursor:pointer;

        position: absolute;
        top: 1em;
        left: 1.7em;
    }
    .contenutoSidebar{
        display: inline-grid;
        grid-template-rows: 15% auto;
        width: 100%;
    }
        
    .menuIcon:hover{
        color: #DB2219;
    }

    .sidebar a{
        text-decoration:none;
        color:white;
    }

    .sidebar a:hover{
        color: #DB2219;
        font-weight: bold;
    }

    .sidebar .active{
        font-weight: bold;
        color:#DB2219;
    }

    .iconeSidebar{
        display: flex;
        font-size: x-large;
        justify-content: space-evenly;
    }
    .linkSidebar{
        padding: 1em;
        font-size: x-large;
        transition: all 0.5s;
        margin: 0 1em 0 0.4em;
    }
    .linkSidebar p{
        margin:0.5em 0;

    }

    .dateTimeContainer{
        display:none;
    }

    .logOutDiv{
        font-size: x-large;
        z-index:20;
        position: absolute;
        top: 1em;
        right: 1.7em;
    }
    .logOutText{
        display:none;
    }


    /* SIDEBAR CHIUSA */
    .sidebarChiusa{
        height:7em;
        transition: all 0.5s;
    }

    .linkSidebarChiuso{
        /* display:none;
        height:0%;
        transition: all 0.5s; */

        transition: all 0.5s;
        height:0%;
        opacity: 0;
        padding:0;
        visibility: hidden;

    }
    .linkSidebarChiuso p{
        display:none;
    }


    /* PARTE A DESTRA */
    .main{
        position:absolute;
        margin-top:7em;
        background:#dce2e1;
        transition: all 0.5s;
        padding-top:1em;
        width:100%;
        overflow-x: hidden;
        min-height:85%;
    }

    .contenutoMain{
        padding:2%;
    }

    .titleAGE{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: xx-large;
        font-weight: 600;
        margin:0 0 0.6em;
    }
    .titleAGE img{
        height: 50px;
        margin:0 0.3em;
        cursor: pointer;
       /*  animation: mymove 5s infinite; */

    }
    .titleAGE span{
        margin:0 0.3em;
        font-size: small;
    
    }

    .immagineApertaDivSidebar{
        opacity: 1;
        z-index: 21;
        transition: all 0.5s;
        position: fixed;
        top: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);
    }
    
    .immagineApertaDivSidebar img{
        cursor: pointer;
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
    
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }
}

