@media (min-width: 800px) {
    .contenutoMainHomepage{
        padding: 2% 4%;
        min-height: 94%;
    }

    .dashboardGrid{
        display:grid;
        grid-template-rows: auto auto auto auto ;
        grid-template-columns: 35% 35% 30%;
    }

    .dashboardLeftMunicipi{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom:1em;
    }

    .dashboardLeft{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom:1em;
        margin-top:4em;
    }

    .dashboardCenter{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom:1em;
        margin-top:4em;
    }

    .dashboardRight{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom:1em;
        margin-top:4em;
    }

    .buongiornoDiv{
        text-transform: uppercase;
        color:#486152;
        font-weight: 500;
        font-size:2em;
        text-align: center;
    }

    .mezziAttiviDiv{
        background: white;
        text-align: center;
        font-size: x-large;
        height: 7em;
        width:12em;
        border-radius:1em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        /* position:relative; */
    }


    .leftDivCounter{
        font-size:xx-large;
        font-weight:bold;
    }
    
    
    .mappaHomepageDiv{
        cursor: pointer;
        background: white;
        border-radius:1em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px;
        height: 7em;
        width:12em;
        background: white;
        font-size: x-large;
        text-align:center;
        transition:all 0.5s;
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

    }
    .mappaHomepageDiv:hover{
        -webkit-box-shadow: 0px 4px 25px 0px #486152; 
        box-shadow: 0px 4px 25px 0px #486152;
    }

    .welcomeDiv{
        grid-column: 2/4;
        background: #486152;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom:3em;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
        /* padding: 2em; */

    }
    .welcomeDivMap{
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 2em 0;
    }

    .municipioTitle {
        /* display:none; */
        color:white;
        text-align: center;
        font-size:2em;
        padding:0em 0 1em;
    }

    .cardsDash{
        -webkit-box-shadow: 0px 4px 10px 0px #486152; 
        box-shadow: 0px 4px 10px 0px #486152;
    }


    .chartPriority{
        background: white;
        text-align: center;
        font-size: x-large;
        height: 16em;
        width:17em;
        border-radius:0.5em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

    }
    .chartRichiedenti{
        background: white;
        text-align: center;
        font-size: x-large;
        height: 16em;
        width:17em;
        border-radius:0.5em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .chartIntervento{
        background: white;
        text-align: center;
        font-size: x-large;
        height: 16em;
        width:15em;
        border-radius:0.5em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: center;
        flex-direction: column;
    }

    .containerTipiIntervento{
        padding:2em 0.5em 0;
        display:grid;
        grid-template-columns: auto auto;
        align-items: center;
    }
    .containerTipiIntervento p{
        font-size:medium;
        padding:0.3em  0.5em;
    }
    .containerTipiIntervento p:hover{
        cursor:pointer;
        font-weight: bold;
        color:#486152;

    }
    .counterTipoTotale{
        font-size:xx-large;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
    }





    .tipoCliccato{
        font-weight: bold;
        color:#486152;
    }
    .dashboardLeftTabella{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top:2em;
        margin-bottom:1em;
    }

    .countersRichieste{
        background: white;
        text-align: center;
        font-size: x-large;
        height: 13em;
        width:12em;
        border-radius:0.5em;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px; 
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .containerIcone{
        display:flex;
        display: flex;
        margin-top: 2.5em;
    }

    .containerIcone svg{
        font-size:x-large;
        margin:0.5em;
        cursor:pointer;
    }
    
    .containerIcone .attivo{
        color:#DB2219;
    }

    .inseriteDiv{
        width: fit-content;
        margin:1em;
        background: white;
        padding: 3em;
        padding-bottom:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
    }


    .pNumeriCounter{
        padding:1em;
        margin-bottom:1em;
        font-size:xx-large;
        font-weight: bold;
    }

    .mappaContainerVisualizza{
        height:74vh;
        transition: all 1s;
        margin:1% 0;
    }

    .contenutoMainHomepage .closed {
        height:0%;
    }

    .faTimes{
        position:absolute;
        right: 5vw;
        font-size: xx-large;
        cursor:pointer;
    }
/* MAPPA VISUALIZZA */
    .mappaComponentVisualizza{
        margin-top: 1.5em;
        position: absolute;
        width: 90%;
        top: 0;
        left: 2%;
        height: 90%;
        z-index: 5;
        background-color: #486152;
        color: white;
        padding: 1em 3em 2em;

        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4)); 
    }

    .legendaVisualizzaMappa{
        padding-left: 4%;
        display: inline-block;
        font-size: smaller;
    }

    .markerPopupVisualizza{
        text-align: center;
        cursor:pointer;
        margin:0 1%;

        transform: all 0.5s
    }
    .markerPopupVisualizza:hover{
        font-weight: bold;
    }
/*     .leaflet-popup {
        bottom: 32px !important;
        left: -61px !important;
    } */
    .popUpAvr{
        bottom: 25px !important;
        left: -50px !important;
    } 
}

@media (max-width: 800px) {
    .contenutoMainHomepage{
        padding:2%;
        padding: 2% 4%;
    }
/*     .dashboardLeft{
        display: grid;
        grid-template-columns: 50% 47%;
        gap:10px;
} */
    .buongiornoDiv{
        text-transform: uppercase;
        color: #486152;
        padding: 1em;
        text-align: center;
        font-size: x-large;
        grid-column: 1/3;
    }

    .mappaHomepageDiv{
        cursor: pointer;
        background: white;
        border-radius:1em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px;
        height: 5em;
        font-size: x-large;
        text-align:center;
        transition:all 0.5s;
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        padding: 0.5em;
        margin:1em;
    }

    .mezziAttiviDiv{
        background: white;
        text-align: center;
        font-size: x-large;
        height: 5em;
        border-radius:1em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.5em;
        flex-direction: column;margin:1em;
    }

    .welcomeDiv{
        background: #486152;
        margin:2em 0;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
    }

    .municipioTitle {
        color:white;
        text-align: center;
        font-size:1.8em;
        padding:1em;
    }

    .counterMunicipi{
        color:white;
        font-weight: bold;
        font-size:1em;
        padding: 2em;
        text-align:center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .numeriDashboard{
        padding: 0.5em;
        font-size: x-large;
    }

    .chartPriority{
        grid-column: 1/3;
        padding:1em 0;
        background: white;
        text-align: center;
        font-size: x-large;
        border-radius:1em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        margin: 1em 0;
    }
    #reactgooglegraph-1 {
        font-family: 'Montserrat' !important;
    }


    .chartRichiedenti{
        background: white;
        text-align: center;
        font-size: x-large;
        border-radius:1em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin:1em 0;
        padding-top:1em;
    }

    .countersRichieste{
        background: white;
        text-align: center;
        font-size: x-large;
        border-radius:1em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .containerIcone{
        display:flex;
        display: flex;
        margin-top: 1em;
    }

    .containerIcone svg{
        font-size:x-large;
        margin:0.5em;
        cursor:pointer;
    }
    .containerIcone .attivo{
        color:#DB2219;
    }
    .pNumeriCounter{
        margin: 0.5em;
        font-size: xx-large;
        font-weight: bold;
    }
    .dashboardRight{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom:1em;
        margin-top:4em;
    }
    .chartIntervento{
        background: white;
        text-align: center;
        font-size: large;
        height: 16em;
        width: 20em;
        border-radius:0.5em;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px; 
        display:flex;
        justify-content: center;
        flex-direction: column;
        
    }
    
    /* VISUALIZZA */
    .mappaContainerVisualizza{
        height:70vh;
        transition: all 1s;
        margin:1em 0;
    }
    
    .mappaComponentVisualizza{
        z-index: 1;
        width: 90%;
        position: absolute;
        top: 2em;
        left: 2%;
        color: white;
        padding: 1em 1em 3em;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        background-color: #486152;
    }

    .legendaVisualizzaMappa{
        padding:1em  1em;
        display: inline-block;
    }

    .markerPopupVisualizza{
        text-align: center;
        cursor:pointer;
        margin:0 1%;

        transform: all 0.5s
    }
    .markerPopupVisualizza:hover{
        font-weight: bold;
    }

    .popUpAvr{
        bottom: 25px !important;
        left: -50px !important;
    } 
    .faTimes{
        font-size: xx-large;
        cursor:pointer;
        display:block;
        width:100% !important;
    }
    
    .containerTipiIntervento{
        padding:2em 0.5em 0;
        display:grid;
        grid-template-columns: 65% 35%;
        align-items: center;
    }
    .containerTipiIntervento p{
        font-size:medium;
        padding:0.3em  0.5em;
    }
    .containerTipiIntervento p:hover{
        cursor:pointer;
        font-weight: bold;
        color:#486152;

    }
    .counterTipoTotale{
        font-size:xx-large;
        font-weight: bold;

    }
    .tipoCliccato{
        font-weight: bold;
        color:#486152;
    }


}