@media (min-width: 600px) {
    .headGestioneUtenti{
        text-align: center;
        font-size:large;
    }
    .headSingoloUtenteContainer{
        padding:1%;
        display:grid;
        grid-template-columns: 20% 20% 20% ;
        font-weight:bold;
    }

    .singoloUtenteContainer{
        display:grid;
        grid-template-columns: 20% 20% 20% 20% ;
        margin: 0.5% 0;
        padding: 0.5em 0.5em;
    }

    .utentiButtonsNotDeleted{
        cursor:pointer;
        color: green;
        text-align:center;
    }

    .utentiButtonsNotDeleted:hover {
        font-weight: bold;
    }

    .utentiButtonsDeleted{
        cursor:pointer;
        color: red;
        text-align:center;
    }

    .utentiButtonsDeleted:hover {
        font-weight: bold;
    }

    .listaUtentiContainer {
        margin: 1%;
        padding: 1%;
        background:white;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .inserimentoUtenteContainer{
        margin-top:2%;
        padding: 0 2em;

    }
    .campoInserimentoUtente{
        margin:1%;
        display:grid;
        grid-template-columns: 30% 30% 40%;
    }
    .inserimentoUtenteContainerTitle{
        text-align: center;
        font-size:larger;
        margin-bottom:2em;
    }
    .campoInserimentoUtente span{
        padding:0 0.5em ;
    }

    .linkGestioneMezzi{
        cursor:pointer;
    }

    .linkGestioneMezzi:hover{
        color: #DB2219;
        font-weight: bold;
    }
    .suggestionUtente{
        margin:2em 1em;
        font-size:smaller;
    }
}
.red{color:red}
.green{color:green}

@media (max-width: 600px) {

    .headSingoloUtenteContainer{
        display:none;
    }

    .headGestioneUtenti{
        font-size: larger;
        text-align: center;
        padding:1em;
    }
    .inserimentoUtenteContainer{
        margin:0.8em;
        padding:1em;
        font-size: large;
        display: flex;
    flex-direction: column;
    }
    .inserimentoUtenteContainer p{
        margin-bottom:2em;
    }
    .singoloUtenteContainer{
        display: grid; 
        align-items: center;
        background-color: white;
        margin:1em;
        padding:1em ;
        border-radius: 5px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr; 
        grid-template-areas: 
            "nomeUtente nomeUtente eliminaUtente eliminaUtente"
            "ruolo ruolo ruolo ruolo"
            "capoSquadraHead capoSquadraHead capoSquadraHead capoSquadraHead"; 
        }
        .nomeUtente { grid-area: nomeUtente; font-weight: 600; }
        .eliminaUtente { grid-area: eliminaUtente; }
        .ruolo { grid-area: ruolo; }
        .capoSquadraHead { grid-area: capoSquadraHead; }

        .utentiButtonsNotDeleted{
            background-color: #e7eceb;
            padding:0.5em 0;
            cursor:pointer;
            color: #006F61;
            text-align:center;
            font-weight: bold;
            border-radius:5px;
        }
    
        .utentiButtonsDeleted{
            cursor:pointer;
            color: red;
            font-weight: bold;
            border-radius:5px;
            text-align:center;  
            background-color: #e7eceb;
            padding:0.5em 0;
        }

        .campoInserimentoUtente{
            margin:1em 0;
            display:grid;
            grid-template-columns: 50% 50% ;
            align-items: center;
        }

        .smallPassword{
            font-size:small;
            margin:0.5em 0;
        }
        .suggestionUtente{
            margin:2em 1em;
            font-size:smaller;
        }
}