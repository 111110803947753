.containerNotificaComponent{
    padding:2em 2em;
}
.containerNotificaComponent p{
    text-align: center;
    font-size:larger;
}


.containerSendNotification{
    padding: 2em 1em;
}




.BottoniDestinatario{
    font-size: small;
    margin: 1.3em 0.5em;
    padding: 0.7em 1em;
    background: #48615298;
    color:white;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
}

.selectedButton{
    background: #486152;
}



.avvisoConferma{
    color:green;font-weight: bold;
}

@media (min-width: 600px) {

    .containerBottoniDestinatario{
        display: flex;
        align-items: center;
    }
    .testoNotifica{
        display: grid;
        grid-template-columns: 30% 45%;
        align-items: center;
    }

}

@media (max-width: 600px) {

    .testoNotifica{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .testoNotifica label{
        padding: 2em 0;
    }
    .testoNotifica textarea{
        width: 100%;
    }

    .containerNotificaComponent{
        display: flex;
        flex-direction: column;
    }
}