
.bottoneConcludiEmergenza{
    font-size: large;
    margin: 1.3em 0.5em;
    padding: 0.7em 1em;
    background: #DB2219;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom: 3px solid #97130c;
    border-right: 2px solid #97130c;
    transition: all 0.5s;
}

.bottoneConcludiEmergenza:active {
    border: none;
}

.headEmergenzaPage{
    text-align: center;
    font-size: larger;
}

.containerButton{
    text-align: center;
}