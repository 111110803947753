@media (min-width: 600px) {
    /* MAPPA INSERISCI */
    .mappaComponent{
        position:relative;
        background-color:#486152;
        color:white;
        padding: 1em 3em 1em;
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4)); 
        margin-bottom:2em;
    }
    .headMappa{
        /* display: inline-block; */
        text-align: center;
        font-weight: bold;
        margin-bottom: 1.5em;
        margin-top: 0.5em;
        /* width: 95%; */
    }
    .containerIconaEtichette{
        position:absolute;
        top:1.5em;
        right:7em;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .containerIconaEtichette svg{
        cursor:pointer;
        margin:0 0.5em;
    }
    .containerIconaEtichetteSpan{
        font-size: smaller;
        display:none;
    }

    .containerIconaEtichette svg:hover{
        color:#DB2219;
    }
    .containerIconaEtichette svg:hover + .containerIconaEtichetteSpan{
        display:inline;
    }




    .containerSearchMapOpen label{
        font-weight: bold;
    }
    .containerSearchMapClosed label{
        font-weight: bold;
    }
    .containerSearchMapOpen{
        margin: 0.5em 0 0 1em ;
        transition: all 0.5s;
        opacity:1;
        height:4em;

    }
    .containerSearchMapClosed{
        margin: 0.5em 0 0 1em ;
        transition: all 0.5s;
        opacity:0;
        height:0vh;

    }
    .mappaComponent .bottoneConferma{
        margin: 0.2% 0% 0% 0%;
    }

 
    .headMappa:hover{
        cursor:pointer;
    }

/* PARTE CHE SI APRE/CHIUDE */
    .mappaContainer{
        height:70vh;
        transition: all 1s;
        margin-top:1%;
    }

    .mappaContainer .leaflet-container {
        cursor: pointer;
    }
    .closed{
        height:0vh;
    }
    .leaflet-container {
        width: 100%; 
        height: 100%;
        z-index:0;
        border-radius:15px;
    }


    .smallerFont{
        font-size:smaller;
    }


    .titoloformInserimentoRichiesta{
        text-align: center;
        font-size: large;
        font-weight: 700;
    }
    .formInserimentoRichiestaLeft{
        padding-top:2em;
    }
    .formInserimentoRichiestaRight{
        padding-top:2em;
        
    }
    .titoloDatiContainer{
        font-weight: bold;

    }
    .datiGeograficiContainer{
        display: grid;
        grid-template-columns: 48% 48%;
        margin: 0.5em 0;

    }
    .datiGeneraliContainer{
        display: grid;
        grid-template-columns: 48% 48%;
        margin: 0.5em 0;

    }
    .campoInserimentoRichiesta{
        margin: 0.5em 1em;
        display:grid;
        grid-template-columns: 45% 50%;
        align-items: center;
    }

    .campoInserimentoRichiestaMEZZI{
        margin:3em 0.5em;
        display:grid;
        grid-column: 1/3;
        grid-template-columns: 50% 47%;
    }

    .campoInserimentoRichiestaMEZZI select{
        overflow: auto;
    }
    .bottoneVai{
        background:#dce2e1;
        color:black;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        padding:0.5em;
        border-radius:0.2em;
        border-bottom:3px solid #28332c;
        border-right:2px solid #28332c;
        margin:1em 1em;
        transition: all 0.5s;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    .bottoneConferma{
        color:white;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        padding:0.7em 1em;
        border-radius:0.2em;
        background:#486152;
        border-bottom:3px solid #28332c;
        border-right:2px solid #28332c;
        margin:1em 1em;
        transition: all 0.5s;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }
    
    .bottoneConferma:active{
        border:none;
    }

    .bottoneModifica{
        color:white;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        padding:1%;
        border-radius:0.2em;
        background: #DB2219;
        border-bottom:3px solid #97130c;
        border-right:2px solid #97130c;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        margin:1em 1em;
    }

    .bottoneModifica:active{
        border:none;
    }


    .angleUp{
        position:absolute;
        right: 5vw;
        bottom: 3vh;
        font-size: xx-large;
        cursor:pointer;
    }
    .angleDown{
        position:absolute;
        right: 5vw;
        bottom: 1vh;
        transform: rotateZ(3.142rad);
        font-size: xx-large;
        cursor:pointer;

    }
    .containerBottoniLayers{
        display: flex;
        align-items: center;
        padding:0.5em ;
        position: absolute;
        flex-direction: column;
        top: 1em;
        right: 1em;
        width:13em;
        background:#486152;
        font-size: small;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;

    }
    .bottoniLayersDiv{
        margin:1em;

    }
    .iconaLayers{
        cursor: pointer;
        font-size: large;
    }

    .bottoneLayer{
        cursor:pointer;
        background:#dce2e1;
        color:black;
        border-radius:2em;
        padding:0.5em;
        margin: 0.5em 0;
    }
    .pressed1{
        background:red;
        color:white;
    }
    .pressed2{
        background:blue;
        color:white;
    }
    .pressed3{
        background:yellow;
        color:red;
    }
    .pressed4{
        background:brown;
        color:white;
    }
    .pressed5{
        background:green;
        color:white;
    }
    .pressed6{/* https://css-tricks.com/stripes-css/ */
        background:rgb(102 175 248);
        color:white;
    }

    .titoloModifica{
        font-weight: 500;
        color:white;
        text-align: right;
        font-size:large;
        padding:1%;
    }

    .insertImages{
        margin: 1%;
    }
    .avviso{
        /* color:red; */
        font-weight: 600;
        text-align: center;
    }

    .avvisoMappa{
        display:inline-block;
    }
    /* .popUpAvr{
        bottom: 53px !important;
        left: -84px !important;
    }  */
    .containerBottoneInserisci{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.red{color:red}
.green{color:green}
.idRichiestaEsistente{
    cursor:pointer;
    text-decoration: underline;
    font-weight:500;
}

.idRichiestaEsistente:hover{
    font-weight:bold
}

@media (max-width: 600px) {
    .mappaComponent{
        position:relative;
        background-color:#486152;
        color:white;
        padding: 1em 1em 2em ;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        margin-bottom: 2em;
    }
    
    .containerSearchMapOpen label{
        font-weight: bold;
    }
    .containerSearchMapOpen{
        display: inline;
        margin: 2em 1em ;
    }
    .containerSearchMapClosed{
        display: none;
    }
    .mappaContainer{
        height:65vh;
        transition: all 1s;
        margin-top:1em;
        margin-bottom:2em;
    }
    .mappaComponent .bottoneConferma{
        margin: 0.2% 0% 0% 0%;
    }
    
    .headMappa{
        text-align:center;
        font-weight: bold;
        display: inline-block;
        width: 90%;
    }
    .headMappa:hover{
        cursor:pointer;
    }
    .leaflet-container {
        width: 100%; 
        height: 100%;
        z-index:0;
    }
    .closed{
        height:0vh;
        margin:0em;
    }
    
    .angleUp{
        position:absolute;
        right: 5vw;
        font-size: xx-large;
        cursor:pointer;
    }
    .angleDown{
        position:absolute;
        right: 5vw;
        transform: rotateZ(3.142rad);
        font-size: xx-large;
        cursor:pointer;
        bottom: 0.5em;
    }
    .containerIconaEtichette{
        margin:2% 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .containerIconaEtichette svg{
        cursor:pointer;
        margin:0 0.5em;
    }
    .containerIconaEtichetteSpan{
        font-size: smaller;
    }

    .containerBottoneInserisci{
        display:flex;
        flex-direction: column;
    }

    .bottoneVai{
        background:#dce2e1;
        color:black;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        padding:0.5em;
        border-radius:0.5em;
        font-size: large;
        border-bottom:3px solid #28332c;
        border-right:2px solid #28332c;
        margin:1em ;
        transition: all 0.5s;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    .bottoneConferma{
        background: #486152;
        color:white;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        padding:1em;
        border-radius:0.5em;
        font-size: larger;
        border-bottom:3px solid #28332c;
        border-right:2px solid #28332c;
        margin:1em auto;
        transition: all 0.5s;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
    }
    .bottoneConferma:active{
        border:none;
    }

    .titoloModifica{
        display: none;
    }
    .formInserimentoRichiesta{
        padding: 0 0.7em;
        font-size: large;
    }
    .campoInserimentoRichiesta{
        margin:1em 0;
        display:grid;
        grid-template-columns: 40% auto ;
    }

    .campoInserimentoRichiestaMEZZI{
        margin:1em 0;
        display:grid;
        grid-template-columns: 40% auto;

    }
    .campoInserimentoRichiestaMEZZI span{
       display:none;

    }

    .campoInserimentoRichiestaMEZZI select{
        overflow: auto;
    }
    .insertImages{
        margin: 1%;
    }
    .insertImages input{
        font-size: large;
    }
    .avviso{
        display:block;
        /* color:red; */
        padding:0 1em ;
        font-weight: 600;
    }

    .bottoneModifica{
        color:white;
        cursor:pointer;
        display: inline-block;
        text-align: center;
        padding:1em;
        border-radius:0.5em;
        background:#83007F;
        border-bottom:3px solid #490047;
        border-right:2px solid #490047;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        font-size: larger;
    }

    .bottoneModifica:active{
        border:none;
    }

    .avvisoMappa{
        margin:0 0.5em;
    }
    .bottoniLayersDiv{
        margin-top:1em;
    }

    .containerBottoniLayers{
        position: absolute;
        top: 1em;
        right: 1em;
        display: flex;
        align-items: center;
        padding:0 1em 0.5em 1em ;
        flex-direction: column;
        background:#486152;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        width:23%;
    }

    .iconaLayers{
        cursor: pointer;
        font-size: large;
    }

    .bottoneLayer{
        cursor:pointer;
        background:#dce2e1;
        color:black;
        border-radius:2em;
        padding:0.5em;
        margin: 0.3em 0;
        font-size: small;
        text-align: center;

    }
    .pressed1{
        background:red;
        color:white;
    }
    .pressed2{
        background:blue;
        color:white;
    }
    .pressed3{
        background:yellow;
        color:red;
    }
    .pressed4{
        background:brown;
        color:white;
    }
    .pressed5{
        background:green;
        color:white;
    }
    .pressed6{/* https://css-tricks.com/stripes-css/ */
        background:rgb(102 175 248);
        color:white;
    }
}


