

.colonnaCentrata{
    text-align:center;
}

thead input{
    max-width: 12em;
}
.colonnaCentrata input{
    max-width: 7em;
}

@media (min-width: 600px) {
    .mappaSingolaComponent{
        position:relative;
        background-color:#486152;
        color:white;
        padding: 1em 2em 3.5em 2em;
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4)); 
        margin-bottom:2em;
    }

    .headSingolaRichiesta{
        display:grid;
        grid-template-columns: 20% 20% 20%;
    }

    .campiContainerRichiesta{
        display:grid;
        grid-template-columns: 20% 20% 20%;

    }

    .campoSingolaRichiesta{
        margin: 0.5em 1em;
        display:grid;
        grid-template-columns: 45% 50%;
        align-items: center;
    }
    .modificaSquadraButton{
        cursor:pointer;
    }
    .modificaSquadraButton:hover{
        font-weight: bold;
    }
    .campoSingolaRichiesta input{
        font-size: 0.9rem;
    }
    input:disabled{
        border:1px solid;
    }

    .campoSingolaRichiesta textarea:disabled{
        border:1px solid;
        resize: none;
    }
    .eliminaButton{
        background: #486152;
        color: white;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        padding: 0.7em 1em;
        border-radius: 0.2em;
        border-bottom: 3px solid #28332c;
        border-right: 2px solid #28332c;
        margin: 1em 1em;
        transition: all 0.5s;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    .eliminaButtonModal{
        background: #486152;
        color: white;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        padding: 0.7em 1em;
        border-radius: 0.2em;
        border-bottom: 3px solid #28332c;
        border-right: 2px solid #28332c;
        margin: 2.5em 1em;
        transition: all 0.5s;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    
    }

    .containerModal{
        position: fixed; /* Stay in place */
        left: 0;
        top: 0;
        z-index:5;
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */

    }
    .eliminaModal{
        background:white;
        border-radius:20px;
        padding: 2em;
        width: 40%; /* Full width */
        height: 20%; /* Full height */
        position:absolute;
        top:30%;
        left:30%;
        z-index:7;
        text-align:center;

    }

}

@media (min-width:600px) and (max-width:850px){
    .eliminaModal{
        height: 45%;
    }
}
@media (max-width: 600px) {
    .mappaSingolaComponent{
        position: relative;
        color: white;
        padding: 1em 1em 3em;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        background-color: #486152;
    }
    
    .titoloformInserimentoRichiesta{
        text-align: center;
        font-size: x-large;
        padding:1em 0;
    }

    .campoSingolaRichiesta{
        margin:1em 0;
        display:grid;
        grid-template-columns: 40% 60% ;
    }
    .modificaSquadraButton{
        cursor:pointer;
    }
    .modificaSquadraButton:hover{
        font-weight: bold;
    }
    .campoSingolaRichiesta input{
        font-size: 0.9rem;
    }
    input:disabled{
        border:1px solid;
    }

    .campoSingolaRichiesta textarea:disabled{
        border:1px solid;
        resize: none;
    }
    .containerBottoniConferma{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .containerBottoneModifica{
        display: flex;
        justify-content: center;
    }
    .eliminaButton{
        background: #486152;
        color: white;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        padding:1em;
        border-radius:0.5em;
        font-size: larger;
        border-bottom:3px solid #28332c;
        border-right:2px solid #28332c;
        margin:1em auto;
        transition: all 0.5s;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
    }

    .eliminaButtonModal{
        background: #486152;
        color: white;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        padding: 0.7em 1em;
        border-radius: 0.2em;
        border-bottom: 3px solid #28332c;
        border-right: 2px solid #28332c;
        margin: 2em 0;
        transition: all 0.5s;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    .containerModal{
        position: fixed; 
        left: 0;
        top: 0;
        z-index:5;
        background-color: rgba(0,0,0,0.4); 
        width: 100%; 
        height: 100%; 
        overflow: auto; 
    }

    .eliminaModal{
        background:white;
        padding: 2em;
        width: -webkit-fill-available ; 
        height: -webkit-fill-available; 
        position:absolute;
        z-index:7;
        text-align:center;
        font-size: larger;
    }

    .titoloDatiContainer{
        font-size: large;
        font-weight: bold;
    }

}