body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

input, select{
	font-family:'Montserrat', sans-serif;
	padding: 0.4em;
	margin: 0 0.5em;
} 
input{
    border: none;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
select{
    border: none;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 15px;
}
textarea{
	font-family:'Montserrat', sans-serif;
	padding: 0.6em;
	margin: 0 0.5em;
	border: none;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* input[type="file" i]{
	display:none;
} */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, 
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	/* font: inherit; */
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* width */
::-webkit-scrollbar {
	width: 12px;
	height: 15px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 8px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #486152;
	border-radius: 8px;
  }


  .leaflet-tooltip-right {
   		margin-left: 8px !important;
	}
  .leaflet-tooltip{
		padding: 2px !important;
		color: black;
		font-weight: 600;
  }


  
/* 3 PALLINI */
.loaderContainer{
    /* height: 15vh; */
    /* width: 15vw; */
    margin-left: 45%;
}
.lds-ellipsis {
	display: block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-ellipsis div {
	position: absolute;
	background:#486152;

	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
  }
  
  @keyframes lds-ellipsis1 {
	0% {
	  transform: scale(0);
	}
	100% {
	  transform: scale(1);
	}
  }
  
  @keyframes lds-ellipsis3 {
	0% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0);
	}
  }
  
  @keyframes lds-ellipsis2 {
	0% {
	  transform: translate(0, 0);
	}
	100% {
	  transform: translate(24px, 0);
	}
  }